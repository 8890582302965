import { Accordion, AccordionSummary, Grid, MenuItem, TextField, Typography, withStyles } from "@material-ui/core"
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons"
import React from "react"
import ErrorView from "../../../components/ErrorView"
import { Clients, PoolTypes } from "./constants"
import DBItem from "./DBItem"
import { getPeriodsFor } from "./helpers"
import PoolSettingFields from "./PoolSettingFields"
import SegmentForm from "./SegmentForm"
import { BlockAccordionDetails, Form, styles, SubmitBtn } from "./styles"
import { GameInstanceProps, GameInstanceState } from "./types.d"

class GameInstanceForm extends React.PureComponent<GameInstanceProps, GameInstanceState> {
  public render() {
    const { theme, classes, form, expandedPanels, submittable, onSubmit, handleChange, handlePanelChange, error } = this.props
    // console.log('render')
    // console.dir(this.props)
    // console.dir(form)
    if (!theme) {
      return null
    }
    return (
      <Form action="#" onSubmit={onSubmit} noValidate={true} autoComplete="off">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <TextField
              className={classes.formControl}
              label="Product Name"
              onChange={handleChange("description")}
              placeholder="Ex: CBS Bracket Pool Challenge"
              value={form.description}
              multiline={true}
              helperText="Human description for this game instance.  This is the human-readable unique name used mainly by administrators to understand the scope, client, and other details of this game instance."
            />
          </Grid>
          <Grid item={true} xs={12}>
            <TextField
              className={classes.formControl}
              value={form.poolType}
              select={true}
              label="Pool Type"
              onChange={handleChange("poolType")}
              multiline={true}
              helperText="Type of pool(s)"
            >
              {PoolTypes.map(({ label, value, disabled }) => (
                <MenuItem key={value} value={value} disabled={disabled}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item={true} xs={12}>
            <TextField
              className={classes.formControl}
              label="UID"
              onChange={handleChange("uid")}
              value={form.uid}
              placeholder="Ex: cbs-nfl-pickem-manager"
              multiline={true}
              helperText={`Similar to an MPID, the unique identifier of this GameInstance.  Recommended to be parameterized (URL friendly).
                Normally formed by the combination of: <client>-<sport/league>-<gameType>-<poolType>`}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <TextField
              className={classes.formControl}
              value={form.client}
              select={true}
              label="Client"
              onChange={handleChange("client")}
              multiline={true}
              helperText="Client this game instance belongs to.  This allows for reproducting game types for a different client"
            >
              {Clients.map(({ label, value, disabled }) => (
                <MenuItem key={value} value={value} disabled={disabled}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item={true} xs={12}>
            {form.associations.Segment.map((segment, index) => {
              const periods = getPeriodsFor(submittable, index)
              return (
                <Accordion key={index} expanded={expandedPanels.segments === index} onChange={handlePanelChange("segments", index)}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>{`Segment ${index + 1}`}</Typography>
                  </AccordionSummary>
                  <BlockAccordionDetails>
                    <Grid container={true} spacing={2}>
                      <SegmentForm poolType={form.poolType} form={segment} classes={classes} handleChange={handleChange} index={index} />
                      <Grid container={true} spacing={3}>
                        {periods.map((period, idx) => (
                          <DBItem key={idx} header={period.description} data={period} />
                        ))}
                      </Grid>
                      <PoolSettingFields form={segment} classes={classes} handleChange={handleChange} index={index} />
                    </Grid>
                  </BlockAccordionDetails>
                </Accordion>
              )
            })}
          </Grid>
          <Grid item={true} xs={12}>
            <SubmitBtn color="primary" variant="contained" type="button" disabled={true}>
              Add Segment
            </SubmitBtn>
          </Grid>
          <Grid item={true} xs={12}>
            <SubmitBtn color="primary" variant="contained" type="submit">
              Create
            </SubmitBtn>
          </Grid>
          {error && (
            <Grid item={true} xs={12}>
              <ErrorView error={error} hide={false} />
            </Grid>
          )}
          <Grid item={true} xs={12}>
            <ErrorView error={form} title="Form" hide={false} />
          </Grid>
          <Grid item={true} xs={12}>
            <ErrorView error={submittable} title="Submittable" hide={false} />
          </Grid>
          {/* <DBItem header={`test this`} data={{ some: 'dataa'}} /> */}
        </Grid>
      </Form>
    )
  }
}

export default withStyles(styles as any, { withTheme: true })(GameInstanceForm)
