import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@material-ui/core"
import React from "react"
import constants from "../../../../common/constants"
import { PeriodLengthType, SubsectionTypes } from "../../../../common/pool-settings"
import { GameTypes, MenuProps, SeasonTypes, SportTypes } from "./constants"
import { isDisabledValue, isValidValue, sportRenderValue } from "./helpers"
import { FormFieldHr } from "./styles"
import { SegmentProps } from "./types.d"

const SegmentForm: React.SFC<SegmentProps> = (props) => {
  // console.dir(props)
  return (
    <Grid container={true} spacing={2}>
      <Grid item={true} xs={12}>
        <TextField
          className={props.classes.formControl}
          label={`masterProductId`}
          type="number"
          onChange={props.handleChange("Segment", "masterProductId", props.index)}
          value={props.form.masterProductId || ""}
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
          helperText="Must be unique to a season for a gameInstance."
        />
      </Grid>
      {props.poolType !== "CHALLENGE" ? null : (
        <Grid item={true} xs={12}>
          <TextField
            className={props.classes.formControl}
            label={`challengePoolSlug`}
            type="text"
            onChange={props.handleChange("Segment", "challengePoolSlug", props.index)}
            value={props.form.challengePoolSlug || constants.DEFAULT_CHALLENGE_POOL_ID}
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
            helperText={`Slug for challenge pool.  This shows up in the URL and should remain the same across seasons so SEO is accumulated year over year.  The default is '${constants.DEFAULT_CHALLENGE_POOL_ID}'`}
          />
        </Grid>
      )}
      <Grid item={true} xs={12}>
        <TextField
          className={props.classes.formControl}
          label={`productAbbrev`}
          type="text"
          onChange={props.handleChange("Segment", "productAbbrev", props.index)}
          value={props.form.productAbbrev || ""}
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
          helperText="Usually unique to the game instance.  Used to apply styling for authentication pages mainly."
        />
      </Grid>
      <Grid item={true} xs={12}>
        <TextField
          className={props.classes.formControl}
          value={props.form.gameType}
          select={true}
          label="Game Type"
          onChange={props.handleChange("Segment", "gameType", props.index)}
          multiline={true}
          helperText="Type of game.  Used to define the play style"
        >
          {GameTypes.map(({ label, value, disabled }) => (
            <MenuItem key={value} value={value} disabled={disabled}>
              {label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item={true} xs={12}>
        <FormControl className={props.classes.formControl}>
          <InputLabel htmlFor="select-multiple-checkbox">Sports</InputLabel>
          <Select
            multiple={true}
            value={props.form.sportTypes}
            onChange={props.handleChange("Segment", "sportTypes", props.index)}
            input={<Input id="select-multiple-checkbox" />}
            renderValue={sportRenderValue}
            MenuProps={MenuProps}
          >
            {SportTypes.map(({ label, value, disabled }) => (
              <MenuItem key={value} value={value}>
                <Checkbox checked={props.form.sportTypes.includes(value)} disabled={disabled || isDisabledValue("sportTypes", value, props.form)} />
                <ListItemText primary={label} />
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>Sports to select from</FormHelperText>
        </FormControl>
      </Grid>
      {!props.form.sportTypes ? null : (
        <Grid item={true} xs={12}>
          <TextField
            className={props.classes.formControl}
            value={props.form.subsection || ""}
            select={true}
            label="Subsection"
            onChange={props.handleChange("Segment", "subsection", props.index)}
            multiline={false}
            helperText={`Mainly used for unique / specific game types.  Ex: NCAAB tournament vs NCAAB Conference Tournaments`}
          >
            {SubsectionTypes.map(({ label, value, disabled }) => (
              <MenuItem key={value} value={value || ""} disabled={disabled}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      )}
      {/*
    {props.form.gameType !== "NAH" ? null : (<Grid item={true} xs={12}>
      <FormControl className={props.classes.formControl}>
        <InputLabel htmlFor="select-multiple-checkbox">Tournaments</InputLabel>
        <Select
          multiple={true}
          value={props.form.tournamentIds || []}
          onChange={props.handleChange("Segment", "tournamentIds", props.index)}
          input={<Input id="select-multiple-checkbox" />}
          renderValue={sportRenderValue}
          MenuProps={MenuProps}
        >
          {TournamentChoices.map(({label, value, disabled}) => (
            <MenuItem key={value} value={value}>
              <Checkbox checked={(props.form.tournamentIds || []).indexOf(value) > -1} disabled={disabled || isDisabledValue("tournamentIds", value, props.form)}/>
              <ListItemText primary={label} />
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>Tournaments that fall under this season</FormHelperText>
      </FormControl>
    </Grid>)}
      */}
      {!props.form.sportTypes.length ? null : (
        <Grid item={true} xs={12}>
          <TextField
            className={props.classes.formControl}
            label={`Year`}
            type="number"
            onChange={props.handleChange("Segment", "year", props.index)}
            value={props.form.year || new Date().getFullYear()}
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
            helperText={`Year this segment applies to.  Postseason year is the same as the reg season, however there are gotchas, like NCAAB tourneies are 1 year in advance of season.`}
          />
        </Grid>
      )}
      {!props.form.sportTypes.length ? null : (
        <Grid item={true} xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Season</FormLabel>
            <FormGroup>
              <RadioGroup
                name={`season-${props.index}`}
                className={props.classes.group}
                value={props.form.seasonType}
                onChange={props.handleChange("Segment", "seasonType", props.index)}
              >
                {SeasonTypes.filter(({ value }) => isValidValue("seasonType", value, props.form)).map(({ label, value }) => (
                  <FormControlLabel key={value} value={value} control={<Radio />} label={label} />
                ))}
              </RadioGroup>
            </FormGroup>
            <FormHelperText>Which season would this Segment run over</FormHelperText>
          </FormControl>
        </Grid>
      )}

      {!props.form.seasonType ? null : (
        <Grid item={true} xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Period Length</FormLabel>
            <FormGroup>
              <RadioGroup
                name={`period-${props.index}`}
                className={props.classes.group}
                value={props.form.periodLength}
                onChange={props.handleChange("Segment", "periodLength", props.index)}
              >
                {PeriodLengthType.filter(({ value }) => isValidValue("periodLength", value, props.form)).map(({ label, value, disabled }) => (
                  <FormControlLabel key={value} value={value} control={<Radio />} label={label} disabled={disabled} />
                ))}
              </RadioGroup>
            </FormGroup>
            <FormHelperText>Given the season, start and end time determine the Periods in the Season, Segment, or Tournament.</FormHelperText>
            <FormHelperText>Periods determine visual / scoring separation.</FormHelperText>
            <FormHelperText>
              Each Period should represent a full unit of the <code>Period Length</code> length.{"  "}
              Therefore the below start and end should represent the <strong>start of the first period to the end of the last period.</strong>
            </FormHelperText>
            <FormHelperText>
              <strong>Note:</strong> You can have a Game Instance <code>Active</code> and a Period <code>Current</code> and{" "}
              <code>Open for picks</code> even if the Period's <code>startsAt</code> has not been reached yet.
            </FormHelperText>
            <FormHelperText>
              <strong>Note:</strong> You can provide the <code>Rollover</code> date to be on preceding the <code>Start</code> date. This will allow
              for the first Period to start (and trigger the rollover process) on a certain date
            </FormHelperText>
          </FormControl>
        </Grid>
      )}
      {!props.form.periodLength ? null : (
        <Grid item={true} md={4}>
          <TextField
            label="Rollover"
            type="datetime-local"
            value={props.form.rolloverAt}
            className={props.classes.textField}
            onChange={props.handleChange("Segment", "rolloverAt", props.index)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      )}
      {!props.form.periodLength ? null : (
        <Grid item={true} md={4}>
          <TextField
            label="Start"
            type="datetime-local"
            value={props.form.startsAt}
            className={props.classes.textField}
            onChange={props.handleChange("Segment", "startsAt", props.index)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      )}
      {!props.form.periodLength ? null : (
        <Grid item={true} md={4}>
          <TextField
            label="End"
            type="datetime-local"
            value={props.form.endsAt}
            className={props.classes.textField}
            onChange={props.handleChange("Segment", "endsAt", props.index)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      )}
      {!props.form.periodLength ? null : <FormFieldHr>Generated Periods</FormFieldHr>}
    </Grid>
  )
}

export default SegmentForm
