import { Mutation, Query } from "@apollo/client/react/components"
import { ApolloError } from "@apollo/client"
import React from "react"
import { withRouter } from "react-router"
import { GameTypeEnum, PoolTypeEnum, SportTypesEnum, SubsectionEnum } from "../../../../common/enums"
import buildMappingFor from "../../../../common/game-text"
import { DefaultPoolSettings } from "../../../../common/pool-settings"
import Layout from "../../components/Layout"
import LoadingView from "../../components/LoadingView"
import { ADMIN_GAME_INSTANCES_QUERY, ADMIN_UPSERT_GAME_INSTANCE_MUTATION } from "../../queries"
import { Clients, localStorageKey } from "./constants"
import GameInstanceForm from "./GameInstanceForm"
import { castValue, decodeActionParams, handleFormChange, hax, toDbDate } from "./helpers"
import { GameInstanceProps, GameInstanceState, Segment } from "./types.d"

class GameInstanceNew extends React.PureComponent<GameInstanceProps, GameInstanceState> {
  public canAccessDom = false
  public persistTimeoutId: any

  constructor(props: GameInstanceProps) {
    super(props)
    const nowDate = new Date()
    const year = nowDate.getFullYear()
    const SegmentObj = Object.assign(
      {
        gameType: GameTypeEnum.PICKEM,
        subsection: SubsectionEnum.NONE,
        sportTypes: [] as SportTypesEnum[],
        endsAt: `${year}-08-01T12:00`,
        startsAt: `${year}-08-01T12:00`,
        rolloverAt: toDbDate(nowDate),
        year,
        season: "",
        poolSettings: {},
        maxEntriesPerUser: 1,
      },
      DefaultPoolSettings,
    ) as Segment
    this.state = {
      form: {
        description: "",
        uid: "",
        poolType: PoolTypeEnum.CHALLENGE,
        client: Clients[0].value,
        associations: {
          Segment: [SegmentObj],
        },
      },
      submittable: {},
      expandedPanels: {
        segments: 0,
      },
      isMounted: false,
    }
    // console.log('constructor')
    // console.dir(this.props)
    // console.dir(this.state)
  }

  public handleChange = (attribute: string, subResourceNameOrValue?: string, index?: number, nestedKey?: string) => (event) => {
    const newValue = castValue(event.target.value)
    // console.log(`handleChange: ${attribute}, ${subResourceNameOrValue} (${subResourceNameOrValue === 'seasonType'}), ${index}, ${nestedKey}) (newValue: ${newValue} (${typeof(newValue)})`)
    const formerState = this.state
    const form = formerState.form
    const gameInstancesData = this.props.gameInstancesData
    if (handleFormChange(newValue, event.target.checked, form, gameInstancesData, attribute, subResourceNameOrValue, index, nestedKey)) {
      const GameInstance = hax(form, gameInstancesData)
      this.setState({ form, submittable: { GameInstance } })
      if (this.canAccessDom) {
        if (this.persistTimeoutId) {
          window.clearTimeout(this.persistTimeoutId)
        }
        this.persistTimeoutId = window.setTimeout(this.persistFormState, 800)
      }
    }
  }

  public persistFormState = () => {
    try {
      window.localStorage.setItem(localStorageKey, JSON.stringify(this.state.form))
    } catch (err) {
      console.error(err)
    }
  }

  public handlePanelChange = (panel, index) => (_, expanded) => {
    this.setState({
      expandedPanels: Object.assign({}, this.state.expandedPanels, {
        [panel]: expanded ? index : -1,
      }),
    })
  }

  public onSubmit = (event) => {
    // console.debug(event)
    event.preventDefault()
    const state = this.state
    const mapping = state.submittable
    this.props.mutate({ variables: { mapping } }).then(
      (res) => {
        // console.log("success!", res);
        const goToIndex = () => this.props.history.replace("/admin/game-instances")
        const { GameInstance } = mapping
        // console.log("mapping!", mapping);
        const { UpsertRecords } = res.data
        const dbSeasons = UpsertRecords.Season as any[]
        // console.dir(GameInstance)
        const qs = [] as Array<Promise<any>>
        if (GameInstance.poolType === "CHALLENGE") {
          dbSeasons.forEach((seasonDbRow) => {
            const existingSeason = GameInstance.associations.Season.find((sea) => sea.id === seasonDbRow.id)
            if (!existingSeason) {
              // this is a newly created season! lets make the challenge pools!
              const gameMapping = buildMappingFor(GameInstance.uid, seasonDbRow.season, seasonDbRow.year, seasonDbRow.productAbbrev)
              const Pool = {
                displayName: gameMapping.titleWithSuffix,
                searchName: seasonDbRow.challengePoolSlug,
                seasonId: seasonDbRow.id,
                challengeForSeasonId: seasonDbRow.id,
                gameInstanceUid: GameInstance.uid,
              }
              qs.push(this.props.mutate({ variables: { mapping: { Pool } } }))
            }
          })
        }
        return Promise.all(qs).then(goToIndex)
      },
      (err) => console.error(err),
    )
  }

  public componentDidMount() {
    this.canAccessDom = true
    // console.log(`mounted....`);
    const form = this.state.form
    const { gameInstancesData, location, match } = this.props
    const config = decodeActionParams(location.search, form, match.gameInstanceId)
    // console.log(`mounted?`);
    // console.dir(config);
    if (!config) {
      try {
        const persistedFormString = window.localStorage.getItem(localStorageKey)
        if (persistedFormString) {
          Object.assign(form, JSON.parse(persistedFormString))
        }
      } catch (err) {
        console.error(err)
      }
    }
    const GameInstance = hax(form, gameInstancesData)
    this.setState({ form, isMounted: true, submittable: { GameInstance } }, () => {
      // console.log(`after set`)
      // console.dir(this.state)
      // if (config.periods) {
      //
      // }
    })
  }

  public componentWillUnmount() {
    if (this.canAccessDom) {
      this.persistFormState()
    }
  }

  public render() {
    const { gameInstancesData, error } = this.props
    const state = this.state
    // const {form, expandedPanels, submittable} = this.state
    const { form, expandedPanels, submittable } = state
    // console.log('render')
    // console.dir(this.props)
    // console.dir(form)
    if (!this.state.isMounted) {
      return <LoadingView />
    }
    return (
      <GameInstanceForm
        onSubmit={this.onSubmit}
        error={error}
        form={form}
        handleChange={this.handleChange}
        handlePanelChange={this.handlePanelChange}
        submittable={submittable}
        expandedPanels={expandedPanels}
        gameInstancesData={gameInstancesData}
      />
    )
  }
}

const GameInstanceFormWithRouter = withRouter(GameInstanceNew)

interface IQuery {
  error?: ApolloError | undefined
}
export default () => (
  <Layout title="Game Instance">
    <Mutation mutation={ADMIN_UPSERT_GAME_INSTANCE_MUTATION}>
      {(adminUpsertGameInstance, { error }: IQuery) => (
        <Query query={ADMIN_GAME_INSTANCES_QUERY}>
          {(gameInstancesData) => {
            if (gameInstancesData.loading) {
              return <LoadingView />
            }
            return (
              <GameInstanceFormWithRouter
                mutate={adminUpsertGameInstance}
                error={error || gameInstancesData.error}
                gameInstancesData={gameInstancesData.data}
              />
            )
          }}
        </Query>
      )}
    </Mutation>
  </Layout>
)
