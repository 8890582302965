import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@material-ui/core"
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons"
import * as React from "react"
import Code from "../../../components/Code"

class DBItem extends React.PureComponent<{ header: string; data?: any }, any> {
  public render() {
    const props = this.props || ({} as any)
    // const data = !!props.data ? {} : props.data
    // // if (data) {
    // //   Object.keys(props.data).forEach((key) => {
    // //     const val = props.data[key]
    // //     if (typeof(val) !== "object" || Object.keys(val).length <= 0) {
    // //       data[key] = val
    // //     }
    // //   })
    // // }
    return (
      <Grid item={true} xs={3}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{props.header || "(no header)"}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Code>{JSON.stringify(props.data, null, 2)}</Code>
          </AccordionDetails>
        </Accordion>
      </Grid>
    )
  }
}

export default DBItem
