import { FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, Radio, RadioGroup, TextField } from "@material-ui/core"
import React from "react"
import { optionsForGameType } from "../../../../common/pool-settings"
import {
  // BracketMarginOfVictoryBonusTypes, ForgotPickFallbackTypes,MaxGamesPerPlayoffPeriod,
  GamesPerPeriodTypes,
  GameWeightTypes,
  PicksDeadlineTypes,
  RoundBonusTypes,
  SpreadTypes,
  TiebreakerTypes,
} from "./constants"
import { cannotHaveAttribute, isValidValue } from "./helpers"
import { FormFieldHr } from "./styles"
import { SegmentProps } from "./types.d"

const PoolSettingFields: React.SFC<SegmentProps> = (props) => {
  // console.dir(props)
  const { classes, form, index, handleChange } = props
  return (
    <Grid container={true} spacing={2}>
      <FormFieldHr>Pool Settings</FormFieldHr>
      <Grid item={true} xs={12}>
        <FormHelperText>
          All the following settings will be used for the <strong>Challenge</strong> pool, and used as the <code>default</code> for{" "}
          <strong>Manager</strong> style Game Instances.
        </FormHelperText>
      </Grid>
      <Grid item={true} xs={12} />

      {cannotHaveAttribute("maxEntriesPerUser", form) ? null : (
        <Grid item={true} xs={12}>
          <FormControl component="fieldset">
            <TextField
              className={classes.formControl}
              label={`Max ${form.gameType === "BRACKET" ? "Brackets" : "Entries"} per User`}
              type="number"
              onChange={handleChange("Segment", "maxEntriesPerUser", index)}
              value={form.maxEntriesPerUser || 1}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
            />
          </FormControl>
        </Grid>
      )}

      {cannotHaveAttribute("picksDeadlineType", form) ? null : (
        <Grid item={true} xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Picks Deadline</FormLabel>
            <FormGroup>
              <RadioGroup
                name={`picksDeadlineType-${index}`}
                className={classes.group}
                value={form.picksDeadlineType}
                onChange={handleChange("Segment", "picksDeadlineType", index)}
              >
                {PicksDeadlineTypes.map(({ label, value, disabled }) => (
                  <FormControlLabel key={value} value={value} control={<Radio />} label={label} disabled={disabled} />
                ))}
              </RadioGroup>
            </FormGroup>
          </FormControl>
        </Grid>
      )}
      {cannotHaveAttribute("spreadType", form) ? null : (
        <Grid item={true} xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Spread</FormLabel>
            <FormGroup>
              <RadioGroup
                name={`spreadType-${index}`}
                className={classes.group}
                value={form.spreadType}
                onChange={handleChange("Segment", "spreadType", index)}
              >
                {SpreadTypes.map(({ label, value, disabled }) => (
                  <FormControlLabel key={value} value={value} control={<Radio />} label={label} disabled={disabled} />
                ))}
              </RadioGroup>
            </FormGroup>
          </FormControl>
        </Grid>
      )}

      {cannotHaveAttribute("gameWeightType", form) ? null : (
        <Grid item={true} xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Weight</FormLabel>
            <FormGroup>
              <RadioGroup
                name={`gameWeightType-${index}`}
                className={classes.group}
                value={form.gameWeightType}
                onChange={handleChange("Segment", "gameWeightType", index)}
              >
                {optionsForGameType(form.gameType, GameWeightTypes).map(({ label, value, disabled }) => (
                  <FormControlLabel key={value} value={value} control={<Radio />} label={label} disabled={disabled} />
                ))}
              </RadioGroup>
            </FormGroup>
          </FormControl>
        </Grid>
      )}

      {/*cannotHaveAttribute("gameWeights", form) ? null : MaxGamesPerPlayoffPeriod[form.sportTypes[0]].map((gameI) => (
      <Grid key={gameI} item={true} xs={12}>
        <FormControl component="fieldset">
          <TextField
            className={classes.formControl}
            label={`Game ${gameI} Weight`}
            type="number"
            onChange={handleChange("Segment", "gameWeights", index, `game${gameI}Weight`)}
            value={form.gameWeights && form.gameWeights[`game${gameI}Weight`] || 1}
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
          />
        </FormControl>
      </Grid>
          ))*/}

      {/*cannotHaveAttribute("gameWeightTotalPointsPerMatchup", form) ? null : (
    <Grid item={true} xs={12}>
      <FormControl component="fieldset">
        <TextField
          className={classes.formControl}
          label="Total Points per matchup"
          type="number"
          onChange={handleChange("Segment", "gameWeightTotalPointsPerMatchup", index)}
          value={form.gameWeightTotalPointsPerMatchup}
          helperText="Total Points per matchup.  For vegas odds only."
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
        />
      </FormControl>
    </Grid>
        )*/}

      {cannotHaveAttribute("roundBonusType", form) ? null : (
        <Grid item={true} xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Round Bonus</FormLabel>
            <FormGroup>
              <RadioGroup
                name={`gameWeightType-${index}`}
                className={classes.group}
                value={form.roundBonusType}
                onChange={handleChange("Segment", "roundBonusType", index)}
              >
                {RoundBonusTypes.map(({ label, value, disabled }) => (
                  <FormControlLabel key={value} value={value} control={<Radio />} label={label} disabled={disabled} />
                ))}
              </RadioGroup>
            </FormGroup>
          </FormControl>
        </Grid>
      )}

      {/*cannotHaveAttribute("roundBonusTotalPointsPerMatchup", form) ? null : (
    <Grid item={true} xs={12}>
      <FormControl component="fieldset">
        <TextField
          className={classes.formControl}
          label="Total Points per matchup"
          type="number"
          onChange={handleChange("Segment", "roundBonusTotalPointsPerMatchup", index)}
          value={form.roundBonusTotalPointsPerMatchup}
          helperText="Total Points per matchup.  For vegas odds only."
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
        />
      </FormControl>
    </Grid>
        )*/}

      {cannotHaveAttribute("mainTiebreaker", form) ? null : (
        <Grid item={true} xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Main Tiebreaker</FormLabel>
            <FormGroup>
              <RadioGroup
                name={`mainTiebreaker-${index}`}
                className={classes.group}
                value={form.mainTiebreaker}
                onChange={handleChange("Segment", "mainTiebreaker", index)}
              >
                {optionsForGameType(form.gameType, TiebreakerTypes)
                  .filter(({ value }) => isValidValue("mainTiebreaker", value, form))
                  .map(({ label, value, disabled }) => (
                    <FormControlLabel key={value} value={value} control={<Radio />} label={label} disabled={disabled} />
                  ))}
              </RadioGroup>
            </FormGroup>
          </FormControl>
        </Grid>
      )}

      {cannotHaveAttribute("secondaryTiebreaker", form) ? null : (
        <Grid item={true} xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Secondary Tiebreaker</FormLabel>
            <FormGroup>
              <RadioGroup
                name={`secondaryTiebreaker-${index}`}
                className={classes.group}
                value={form.secondaryTiebreaker}
                onChange={handleChange("Segment", "secondaryTiebreaker", index)}
              >
                {optionsForGameType(form.gameType, TiebreakerTypes)
                  .filter(({ value }) => isValidValue("secondaryTiebreaker", value, form))
                  .map(({ label, value, disabled }) => (
                    <FormControlLabel key={value} value={value} control={<Radio />} label={label} disabled={disabled} />
                  ))}
              </RadioGroup>
            </FormGroup>
          </FormControl>
        </Grid>
      )}

      {/*cannotHaveAttribute("forgotPickFallback", form) || true ? null : (
    <Grid item={true} xs={12}>
      <FormControl component="fieldset">
        <FormLabel component="legend">Missed Periods</FormLabel>
        <FormGroup>
          <RadioGroup
            name={`forgotPickFallback-${index}`}
            className={classes.group}
            value={form.forgotPickFallback}
            onChange={handleChange("Segment", "forgotPickFallback", index)}
          >
            {optionsForGameType(form.gameType, ForgotPickFallbackTypes).map(({ label, value, disabled }) => (
              <FormControlLabel key={value} value={value} control={<Radio />} label={label} disabled={disabled} />
            ))}
          </RadioGroup>
        </FormGroup>
      </FormControl>
    </Grid>
            )*/}

      {cannotHaveAttribute("gamesPerPeriod", form) || true ? null : (
        <Grid item={true} xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Games Per Period</FormLabel>
            <FormGroup>
              <RadioGroup
                name={`gamesPerPeriod-${index}`}
                className={classes.group}
                value={form.gamesPerPeriod}
                onChange={handleChange("Segment", "gamesPerPeriod", index)}
              >
                {optionsForGameType(form.gameType, GamesPerPeriodTypes).map(({ label, value, disabled }) => (
                  <FormControlLabel key={value} value={value} control={<Radio />} label={label} disabled={disabled} />
                ))}
              </RadioGroup>
              <FormHelperText>
                If custom, each period the Manager will be allowed to exclude any game they want that falls into the period. Defaults to all.
              </FormHelperText>
            </FormGroup>
          </FormControl>
        </Grid>
      )}

      {cannotHaveAttribute("maxPicksPerPeriodCount", form) ? null : (
        <Grid item={true} xs={12}>
          <FormControl component="fieldset">
            <TextField
              className={classes.formControl}
              label="Max Picks for a period"
              type="number"
              onChange={handleChange("Segment", "maxPicksPerPeriodCount", index)}
              value={form.maxPicksPerPeriodCount}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
            />
          </FormControl>
        </Grid>
      )}

      {/*cannotHaveAttribute("bracketMarginOfVictoryBonus", form) ? null : (
    <Grid item={true} xs={12}>
      <FormControl component="fieldset">
        <FormLabel component="legend">Margin of Victory Bonus</FormLabel>
        <FormGroup>
          <RadioGroup
            name={`bracketMarginOfVictoryBonus-${index}`}
            className={classes.group}
            value={form.bracketMarginOfVictoryBonus}
            onChange={handleChange("Segment", "bracketMarginOfVictoryBonus", index)}
          >
            {BracketMarginOfVictoryBonusTypes.map(({ label, value, disabled }) => (
              <FormControlLabel key={value} value={value} control={<Radio />} label={label} disabled={disabled} />
            ))}
          </RadioGroup>
        </FormGroup>
      </FormControl>
    </Grid>
            )*/}

      {/*cannotHaveAttribute("bracketMarginOfVictoryBonus", form) ? null : (
    <Grid item={true} xs={12}>
      <FormControl component="fieldset">
        <TextField
          className={classes.formControl}
          label="Games per period"
          type="number"
          onChange={handleChange("Segment", "bracketMarginOfVictoryBonus", index)}
          value={form.bracketMarginOfVictoryBonus}
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
        />
      </FormControl>
    </Grid>
        )*/}
    </Grid>
  )
}

export default PoolSettingFields
