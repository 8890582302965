import { Button, AccordionDetails } from "@material-ui/core"
import styled from "styled-components/macro"

export const BlockAccordionDetails = styled(AccordionDetails)`
  display: block;
`

export const Form = styled.form`
  width: 100%;
  max-width: 800px;
  margin: 3vh auto;
  display: flex;
  flex-flow: column wrap;
`

export const SubmitBtn = styled(Button)`
  margin-top: 10px;
`
export const FormFieldHr = styled.div`
  padding: 3rem 0 0;
  margin: 2rem 1rem 2rem 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  font-weight: 400;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.5em;
`

export const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
  },
})
